$(function (window, document, Chartist) {
    'use strict';

    var defaultOptions = {
        threshold: 3,
        classNames: {
            accurateThreshold: 'ct-threshold-accurate',
            predictionThreshold: 'ct-threshold-prediction'
        },
        maskNames: {
            accurateThreshold: 'ct-threshold-mask-accurate',
            predictionThreshold: 'ct-threshold-mask-prediction'
        }
    };

    function createMasks(data, options) {
        // Select the defs element within the chart or create a new one
        var defs = data.svg.querySelector('defs') || data.svg.elem('defs');
        var width = data.svg.width();
        var height = data.svg.height();

        var points = data.svg.querySelectorAll('line.ct-point').svgElements;

        var point4 = points[points.length - options.threshold]._node; //todo think of a better way
        var x1 = point4.x1.baseVal.value;
        var x2 = point4.x2.baseVal.value;
        var y1 = point4.y1.baseVal.value;
        var y2 = point4.y2.baseVal.value;

        // Create mask for left part accurate threshold
        defs
          .elem('clipPath', {
              //x: 0,
              //y: 0,
              //width: width,
              //height: height,
              id: options.maskNames.accurateThreshold
          })
          .elem('rect', {
              x: 0,
              y: 0,
              width: Math.round(x1),
              height: height,
              fill: 'white'
          });

        // Create mask for right part prediction threshold
        defs
          .elem('clipPath', {
              //x: 0,
             // y: 0,
              //width: width,
              //height: height,
              id: options.maskNames.predictionThreshold
          })
          .elem('rect', {
              x: Math.round(x1),
              y: 0,
              width: Math.round(width - x1),
              height: height,
              fill: 'white'
          });

        return defs;
    }

    Chartist.plugins = Chartist.plugins || {};
    Chartist.plugins.ctVertThreshold = function (options) {
        options = Chartist.extend({}, defaultOptions, options);

        return function ctVertThreshold(chart) {
            if (chart instanceof Chartist.Line || chart instanceof Chartist.Bar) {
                chart.on('draw', function (data) {
                    if (data.type === 'point') {
     
                        data.element.addClass(
                          (data.index >= options.threshold) ? options.classNames.predictionThreshold : options.classNames.accurateThreshold
                        );
                    } else if (data.type === 'line' || data.type === 'bar' || data.type === 'area') {
                        // Cloning the original line path, mask it with the upper mask rect above the threshold and add the
                        // class for above threshold
                        data.element
                          .parent()
                          .elem(data.element._node.cloneNode(true))
                          .attr({
                              'clip-path': 'url(#' + options.maskNames.accurateThreshold + ')'
                          })
                          .addClass(options.classNames.accurateThreshold);

                        // Use the original line path, mask it with the lower mask rect below the threshold and add the class
                        // for blow threshold
                        data.element
                          .attr({
                              'clip-path': 'url(#' + options.maskNames.predictionThreshold + ')'
                          })
                          .addClass(options.classNames.predictionThreshold);
                    }
                });

                // On the created event, create the two mask definitions used to mask the line graphs
                chart.on('created', function (data) {
                    createMasks(data, options);
                });
            }
        };
    }
}(window, document, Chartist));