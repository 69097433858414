// General dialog
$(document.body).on('click', '[data-click-dialog]', function (e) {
    e.preventDefault();
    var $this = $(this);

    $.ajaxDialog($(this).attr('data-click-dialog')).done(function (e) {
        if (e.Content && e.Content.Refresh) {
            location.reload(true);
        } else {
            $this.trigger('dialogDone');
        }
    });
});

// General action
$(document.body).on('click', '[data-click-action]', function (e) {
    e.preventDefault();
    var $this = $(this);

    var confirmText = $(this).attr('data-click-action-confirm');
    if (confirmText && !confirm(confirmText))
        return;

    $.ajaxAction($(this).attr('data-click-action')).done(function (e) {
        if (e.Content && e.Content.Refresh) {
            location.reload(true);
        } else {
            $this.trigger('actionDone');
        }
    });
});


// Form submit via Ajax
$('body').on('submit', 'form:not([no-ajax])', function (e) {
    e.preventDefault();

    if (!$(this).find('[data-val]').validate() || $(this).data('ajax-busy') === true)
        return;

    $(this).data('ajax-busy', true);

    var $form = $(this);

    $.ajaxAction($(this).attr('action'), $(this).serialize())
        .done(function (data) {
            var submitDoneEvent = $.Event("submitDone");
            $form.trigger(submitDoneEvent, data);
        })
        .fail(function (data) {
            var submitFailEvent = $.Event("submitFail");
            $form.trigger(submitFailEvent, data);

            if (submitFailEvent.isDefaultPrevented())
                return;

            if (data.ModelErrors && data.ModelErrors.length > 0)
                $.message("Fout bij verzenden van gegevens", data.ModelErrors.join("<br>"), "error");
            else
                $.message("Fout bij verzenden van gegevens", "Geen details beschikbaar.", "error");

        }).always(function () {
            $form.data('ajax-busy', false);
        });
});

// Input picker
$('.input-picker').on('focusin', function () {
    $(this).find('.input-picker-list').addClass('active');
    $(this).closest('.input-block').addClass('active');
}).on('focusout', function () {
    $(this).find('.input-picker-list').removeClass('active');
    $(this).closest('.input-block').removeClass('active');
}).on('click', '.input-picker-list > div:not(.input-picker-header)', function () {
    var selection = $(this).text();
    var $picker = $(this).closest('.input-picker');

    $picker.children('span').first().text(selection);
    $('#' + $picker.attr('data-input')).val($(this).attr('data-value')).trigger("change");

    $(this).blur();
}).on('keypress', '.input-picker-list > div:not(.input-picker-header)', function (e) {
    if (e.which !== 13)
        return;

    var selection = $(this).text();
    var $picker = $(this).closest('.input-picker');

    $picker.children('span').first().text(selection);
    $('#' + $picker.attr('data-input')).val($(this).attr('data-value')).trigger("change");

    $(this).blur();
});

// Input mask
$(":input[data-inputmask]").inputmask();

// Image viewer
$('body').on('click', 'a', function (e) {
    var url = $(this).attr('href');
    if (!url)
        return;

    var title = $(this).attr('data-title') || "";

    var extension = url.split('.').pop();
    if ($.inArray(extension, ['jpg', 'png', 'gif']) === -1)
        return;

    e.preventDefault();
    $('body').addClass('modal-open');
    $('<div id="page-image-container"><div><img src="' + url + '" /><div style="padding-top: 10px; font-weight: bold">' + title + '</div></div></div>').appendTo('body').one('click', function() {
        $(this).remove();
        $('body').removeClass('modal-open');
    });
});