(function ($) {
    function escapeAttributeValue(value) {
        return value.replace(/([!"#$%&'()*+,./:;<=>?@\[\\\]^`{|}~])/g, "\\$1");
    }

    function getModelPrefix(fieldName) {
        return fieldName.substr(0, fieldName.lastIndexOf(".") + 1);
    }

    function appendModelPrefix(value, prefix) {
        if (value.indexOf("*.") === 0) {
            value = value.replace("*.", prefix);
        }
        return value;
    }

    var validationRules = {
        'required': function(options) {
            return !!this.value;
        },
        'email': function(options) {
            return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(this.value);
        },
        'date': function(options) {
            return /^\d\d?\-\d\d?\-\d\d\d\d$/.test(this.value);
        },
        'equalto': function(options) {
            var prefix = getModelPrefix(this.name),
                other = this.dataset.valEqualtoOther,
                fullOtherName = appendModelPrefix(other, prefix),
                otherElement = $(this.form).find(":input").filter("[name='" + escapeAttributeValue(fullOtherName) + "']")[0];

            return this.value === otherElement.value;
        },
        'minlength': function(options) {
            var minLength = $(this).data("valMinlengthMin");
            var inputValue = this.value;

            if (inputValue.length < minLength) {
                return false;
            }
            return true;
        },
        'maxlength': function(options) {
            var maxLength = $(this).data("valMaxlengthMax");
            var inputValue = this.value;

            if (inputValue.length > maxLength) {
                return false;
            }
            return true;
        },
        'number' : function(options) {
            return /^-?(?:\d+|\d{1,3})?(?:[\.,]\d+)?$/.test(this.value);
        },
        'requiredif': function (options) {
            var prefix = getModelPrefix(this.name),
            other = this.dataset.valRequiredifProperty,
            fullOtherName = appendModelPrefix(other, prefix),
            $checkbox = $(this.form).find(":input").filter("[name='" + escapeAttributeValue(fullOtherName) + "']")[0];

            if (($($checkbox).val() === "true" ||  $($checkbox).is(":checked")) && this.value.length === 0)
                return false;

            return true;
        }
};

    // Creates an object of the rules defined in the data attributes of the input element
    // [this] the input element
    var getUnobtrusiveRules = function () {
        var rules = {};

        for (var i = 0; i < this.attributes.length; i++) {
            if (this.attributes[i].name.indexOf("data-val-") !== 0)
                continue;

            var name = this.attributes[i].name.substr(9).split("-");

            if (!rules[name[0]])
                rules[name[0]] = {};

            rules[name[0]][name[1] || 'message'] = this.attributes[i].value;
        }

        return rules;
    };

    // Creates an array of validation errors or a single error if 'stopAtFirst' is true for the input element
    // [this] the input element
    // [rules] object of rules
    // [stopAtFirst] stop at first validation error
    var getErrors = function (rules, stopAtFirst) {
        //var value = $(this).val();
        var errors = [];

        //if (!rules["required"] && this.value.length == 0)
        if (!rules["required"] && !rules["requiredif"] && this.value.length == 0)
            return stopAtFirst ? false : errors;

        for (var rule in rules) {
            if (!validationRules[rule]) {
                console.log("Unknown validation: " + rule);
                continue;
            }

            //var ruleValid = validationRules[rule](rules[rule], value);
            var ruleValid = validationRules[rule].apply(this, rules[rule]);

            if (!ruleValid && stopAtFirst)
                return rules[rule];
            else if (!ruleValid)
                errors.push(rules[rule]);

        }

        return stopAtFirst ? false : errors;
    };

    $.fn.validate = function (silent) {
        var hasError = false;
        $(this).each(function () {
            var rules = getUnobtrusiveRules.call(this);
            var error = getErrors.call(this, rules, true);

            if (error)
                hasError = true;

            if (silent)
                return !hasError;

            var hasErrorState = !!$(this).attr('data-error');

            if (!hasErrorState && error) {
                $(this).attr('data-error', error.message);
                $(this).addClass('invalid').closest('.input-block').addClass('invalid').attr('title', error.message);
            } else if (hasErrorState && !error) {
                $(this).removeAttr('data-error');
                $(this).removeClass('invalid').closest('.input-block').removeClass('invalid').removeAttr('title');
            }
        });

        if (this.length > 1 && !silent)
            $(this).filter('.invalid').first().focus();

        return !hasError;
    };

    // Initialization
    $(function () {
        var events = ('oninput' in window) ? 'input' : 'change';
        $(document.body).on(events, '[data-val]', function () {
            $(this).validate();
        });
    });
}(jQuery));