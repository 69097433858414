(function ($) {

    $.ajaxAction = function (url, data) {
        var isObject = (typeof data == 'object');
        var deferred = $.Deferred();

        if (isObject) {
            data = JSON.stringify(data);
        } else if ($.isNumeric(data)) {
            data = 'id=' + data;
        }

        //$.loading(true);

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            cache: false,
            dataType: 'json',
            contentType: isObject ? 'application/json; charset=utf-8' : 'application/x-www-form-urlencoded; charset=utf-8'
        }).fail(function(jqXHR, textStatus, errorThrown) {
            //$.loading(false);

            // User is navigating away from page
            if (jqXHR.status == 0)
                return;

            var resultData;
            try {
                resultData = $.parseJSON(jqXHR.responseText);
            } catch (e) {
                window.fatalError("Invalid JSON response", "Action: " + this.url);
                return;
            }

            if (resultData.Result != "PostJsonResult") {
                window.fatalError("Invalid response (PostJsonResult expected)", "Action: " + this.url);
                return;
            }

            deferred.reject(resultData);

        }).done(function(resultData, textStatus, jqXHR) {
            //$.loading(false);

            if (resultData.Result != "PostJsonResult") {
                window.fatalError("Invalid response (PostJsonResult expected)", "Action: " + this.url);
                return;
            }

            if (resultData.Refresh) {
                location.reload(true);
                return;
            }
            else if (resultData.RedirectUrl) {
                location.href = resultData.RedirectUrl;
                return;
            }
   
            deferred.resolve(resultData);
        });

        return deferred.promise();
    };

}(jQuery));