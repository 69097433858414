(function ($) {
    var hideMessage = function () {
        var $message = $(this);
        var heigth = $message.outerHeight() + 10;
        $message.css('bottom', -heigth);

        setTimeout(function () {
            $message.remove();
        }, 1000);
    };

    $.message = function (title, text, type) {
        $('.message').each(function() {
            hideMessage.call(this);
        });
         
        var messageClass = "message";
        if (type === "error")
            messageClass += " message-error";

        var $message = $('<div class="' + messageClass + '"><strong>' + title + '</strong><div>' + text + '</div></div>');
        $(document.body).append($message);

        var width = $message.outerWidth();
        $message.css('left', -width).addClass('animating');

        setTimeout(function() {
            $message.css('left', "30px");
        }, 10);

        setTimeout(function () {
            hideMessage.call($message[0]);
        }, 4000);

    };
}(jQuery));